import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { IButtonProps } from '@fluentui/react/lib/Button';
import { IColumn, IDetailsRowProps, DetailsRow, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { ShimmeredDetailsList } from '@fluentui/react/lib/ShimmeredDetailsList';

import { IAIEmptyContent } from '../IAIEmptyContent';
import { IAIDataTableCommandBar } from './components/IAIDataTableCommandBar';
import IAIPagination from './components/IAIPagination/iai-pagination';

type IPaginationModule = {
    totalCount: number;
    page: number;
    limit: number;
    onPageChage: (newPage: number) => void;
}

interface IIAIDataColumn extends IColumn {
    sorter?: (isSortedDescending?: boolean, recordA?: string, recordB?: string) => 1 | -1 | 0;
}

interface IIAIDataTable {
    dataSource: any[];
    columns?: IIAIDataColumn[];
    dataTableCommands?: IButtonProps[];
    dataLoading?: boolean;
    emptyContentLabel: string;
    onItemInvoked?: (item?: any, index?: number, ev?: Event) => void;
    searchable?: boolean;
    onSearch?: (entry: any, newValue: string) => boolean;
    filterable?: boolean;
    filterActive?: boolean;
    onFilter?: () => void;
    pagingModule?: IPaginationModule;
}

const IAIDataTable: React.FunctionComponent<IIAIDataTable> = ({
    dataSource,
    columns,
    dataTableCommands,
    dataLoading,
    emptyContentLabel,
    onItemInvoked,
    searchable,
    onSearch,
    filterable,
    filterActive,
    onFilter,
    pagingModule
}) => {
    const [listDataSource, setListDataSource] = useState([...dataSource]);
    const [listColumns, setListColumns] = useState(columns);

    useEffect(() => {
        setListDataSource(dataSource);
    }, [dataSource]);

    useEffect(() => {
        setListColumns(columns);
    }, [columns]);

    const _handleColumnSorting = (ev?: React.MouseEvent<HTMLElement>, column?: IColumn): void => {
        if (!ev || !column) {
            return;
        }

        let contextColumns = _.map(_.slice(columns), entry => ({
            ...entry,
            isSorted: entry.key === column.key,
            isSortedDescending: entry.key === column.key ? !column.isSortedDescending : true
        }));

        let currentCol = _.find(columns, entry => entry.key === column.key);
        let contextItems = _.slice(dataSource).sort((recordA, recordB) =>
            currentCol && currentCol.sorter
                ? currentCol.sorter(column.isSortedDescending, recordA[`${column.fieldName}`], recordB[`${column.fieldName}`])
                : -1
        );

        setListColumns(contextColumns);

        setListDataSource(contextItems);
    };

    const _handleSearch = (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string): void => {
        if (!newValue || !onSearch) {
            setListDataSource(dataSource);
        }
        else {
            setListDataSource(_.filter(dataSource, entry => onSearch(entry, newValue)));
        }
    };

    const _handleRenderRow = (props?: IDetailsRowProps) => {
        if (!props) {
            return null;
        }

        if (props.item.status !== 'closed' && !!props.item.reopenAt) {
            return (
                <DetailsRow {...props} styles={{ root: { backgroundColor: 'rgba(241, 195, 80, .75)' } }} />
            );
        }

        return (
            <DetailsRow {...props} />
        );
    }

    return (
        <React.Fragment>
            <IAIDataTableCommandBar
                commands={dataTableCommands}
                disabled={dataLoading}
                searchable={searchable}
                onSearch={_handleSearch}
                filterable={filterable}
                filterActive={filterActive}
                onFilter={onFilter}
            />

            <ShimmeredDetailsList
                setKey={'iai_set_ke__'}
                items={listDataSource}
                columns={listColumns}
                enableShimmer={dataLoading}
                selectionMode={SelectionMode.none}
                onItemInvoked={onItemInvoked}
                onColumnHeaderClick={_handleColumnSorting}
                onRenderRow={(props) => _handleRenderRow(props)}
            />

            {!listDataSource.length && !dataLoading && (
                <IAIEmptyContent title={emptyContentLabel} />
            )}

            {pagingModule && (
                <IAIPagination
                    page={pagingModule.page}
                    totalCount={pagingModule.totalCount}
                    limit={pagingModule.limit}
                    onPageChage={pagingModule.onPageChage}
                />
            )}
        </React.Fragment>
    );
}

export default IAIDataTable;